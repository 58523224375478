/* home.module.css */

.scrollable {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #4a4a4a #f0f0f0; /* For Firefox */
  
    /* For Webkit browsers (Chrome, Safari, etc.) */
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #4a4a4a;
      border-radius: 10px;
      border: 3px solid #f0f0f0;
    }
  }


  .resizer-r {
    width: 5px;
    background-color: #d1d5db; /* Tailwind's gray-300 */
    cursor: ew-resize;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 10;
    transition: background-color 0.2s ease;
}

.resizer-r:hover {
    background-color: #4b5563; /* Tailwind's gray-500 */
}
.gradient-shadow {
  position: relative;
}
.gradient-shadow::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50px; /* Adjust based on the shadow width */
  width: 50px; /* Shadow width */
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 15, 0.1), rgba(0, 0, 15, 0));
  pointer-events: none; /* Ensures it doesn't block interactions */
  z-index: -1; /* Places it behind the main content */
}
