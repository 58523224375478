/* sidebar.css */

/* Dropdown menu */

.list-item {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.dropdown-menu {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: scale(0.95);
}

.dropdown-menu.open {
  opacity: 1;
  transform: scale(1);
}

.menu-toggle {
  position: relative;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px; /* Adjust spacing between menu items */
  transition: all 0.3s ease;
}

.menu-toggle::before {
  content: '';
  position: absolute;
  top: 0;
  left: -8%;
  width: 2px; /* Slightly thicker line */
  height: 100%; /* Full height of the menu-toggle */
  background-color: #555; /* Adjust as per your design */
  transition: background-color 0.3s ease;
}

.menu-toggle:hover::before {
  background-color: #888; /* Lighter color on hover */
}

.channel-item {
  position: relative;
  transition: color 0.3s ease;
  left: -10.5%;
}
.channel-item:hover {
  color: #ffffff; /* Example text color on hover */
  z-index: 10; /* Ensure hover effect is above other elements */
}

.channel-item.selected {
  color: #007bff; /* Blue color for selected channel */
}

.channel-item.selected::before {
  content: '';
  position: absolute;
  left: -12px; /* Adjust to align with the vertical line */
  top: 50%; /* Position the dot vertically centered */
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #007bff; /* Blue dot */
  border-radius: 50%;
  z-index: 1; /* Ensure the dot is above the line */
}
