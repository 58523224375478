/* App.css */

/* Import a font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


/* Define a global style for the font family */
body {
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}  
  .inter-regular {
    font-family: "inter", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .inter-medium {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .inter-semibold {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .inter-bold {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .inter-extrabold {
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  